const axios = require('axios');
import {config} from '@/assets/js/Utils.js';

export function getLoyaltyInfos(customerComplexId){
    let path = "/customer/get/loyalty/infos"
    let jsonInfos = {complexId: customerComplexId}
    let params = {jsonInfos: JSON.stringify(jsonInfos)}

    return axios.get(config.WS_URL + path, { params: params })
}
