<template>
    <div class="orders">

        <v-card
            class="pa-4 custom-card"
        >
            <v-row class="mt-6">
                <v-col cols="3" class="py-0">
          <span class="label">
            {{$t('orders.origin')}}
          </span>
                    <v-select
                        v-model="filter.selectedOrigins"
                        :items="getOriginsAvailable"
                        item-text="name"
                        item-value="value"
                        multiple
                        chips
                        outlined
                        rounded
                        color="primary"
                        :menu-props="{ bottom: true, offsetY: true }"
                    ></v-select>
                </v-col>
                <v-col cols="3" class="py-0">
          <span class="label">
            {{$t('orders.reference')}}
          </span>
                    <v-text-field
                        v-model="filter.reference"
                        outlined
                        rounded
                        color="primary"
                        type="number"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="3" class="py-0">
          <span class="label">
            {{$t('orders.email')}}
          </span>
                    <v-text-field
                        v-model="filter.email"
                        outlined
                        rounded
                        color="primary"
                        type="email"
                    ></v-text-field>
                </v-col>
                <v-col cols="3" class="py-0">
          <span class="label">
            {{$t('orders.status')}}
          </span>
                    <v-select
                        v-model="filter.selectedStatus"
                        :items="$t('orders.evolutions')"
                        multiple
                        chips
                        outlined
                        rounded
                        color="primary"
                        :menu-props="{ bottom: true, offsetY: true }"
                    ></v-select>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="4" class="py-0">
                    <v-row>
                        <v-col cols="6">
              <span class="label">
                {{$t('orders.startDate')}}
              </span>
                            <v-menu
                                v-model="pickerMinDate"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :value="formatMinDate"
                                        outlined
                                        rounded
                                        color="primary"
                                        append-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    active-picker="DATE"
                                    v-model="filter.minDate"
                                    @input="pickerMinDate = false"
                                    locale="fr"
                                    :max="getDateEn()"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="6">
            <span class="label">
              {{$t('orders.endDate')}}
            </span>
                            <v-menu
                                v-model="pickerMaxDate"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :value="formatMaxDate"
                                        outlined
                                        rounded
                                        color="primary"
                                        append-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    active-picker="DATE"
                                    v-model="filter.maxDate"
                                    @input="pickerMaxDate = false"
                                    locale="fr"
                                    :min="filter.minDate"
                                    :max="getDateEn()"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="6">
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="4" class="py-0">
          <span class="label">
            {{$t('orders.minAmount')}}
          </span>
                    <v-text-field
                        v-model="filter.minAmount"
                        outlined
                        rounded
                        color="primary"
                        type="number"
                    ></v-text-field>
                </v-col>
                <v-col cols="4" class="py-0">
          <span class="label">
            {{$t('orders.maxAmount')}}
          </span>
                    <v-text-field
                        v-model="filter.maxAmount"
                        outlined
                        rounded
                        color="primary"
                        type="number"
                    ></v-text-field>
                </v-col>
            </v-row>

            <div class="text-center mt-6">
                <v-btn
                    @click="clearFilters"
                    rounded
                    outlined
                    color="primary"
                    dark
                    width="150"
                >
                    {{$t('orders.reset')}}
                </v-btn>
                <v-btn
                    @click="getOrdersToShow"
                    rounded
                    color="primary"
                    dark
                    width="150"
                    class="ms-4"
                >
                    {{$t('orders.valid')}}
                </v-btn>
            </div>
        </v-card>

        <div class="horizontal-divider"></div>

        <div class="table-wrapper">
            <div class="table-header">
                <span>{{$t('orders.tableHeader1')}}</span>
                <span>{{$t('orders.tableHeader2')}}</span>
                <span>{{$t('orders.tableHeader3')}}</span>
                <span>{{$t('orders.tableHeader4')}}</span>
                <span>{{$t('orders.tableHeader5')}}</span>
                <span>{{$t('orders.tableHeader6')}}</span>
                <div @click="sortDateDesc = !sortDateDesc" class="sort">
                    <span class="me-2">{{$t('orders.tableHeader7')}}</span>
                    <v-icon small class="icon-sort" v-if="sortDateDesc">mdi-arrow-up</v-icon>
                    <v-icon small class="icon-sort" v-else>mdi-arrow-down</v-icon>
                </div>
            </div>
            <div class="horizontal-divider"></div>
            <div class="table-body">
                <template v-if="showVLazy">
                    <v-lazy
                        v-model="order.isActive"
                        :options="{
              threshold: .5
            }"
                        min-height="56"
                        transition="fade-transition"
                        v-for="order in ordersToShow" :key="order.id"
                    >
                        <OrderItem :order="order"></OrderItem>
                    </v-lazy>
                </template>


            </div>
        </div>


    </div>
</template>

<script>
import OrderItem from "@/components/IEC/OrderItem";
var Customer = require("@/assets/js/Customer");
var Basket = require("@/assets/js/Basket");
export default {
    name: 'Orders',
    components: {OrderItem},
    data: () => ({
        orders: null,
        ordersByEmail: null,
        sortDateDesc: true,
        pickerMinDate: false,
        pickerMaxDate: false,
        filter: {
            selectedOrigins: null,
            reference: null,
            email: null,
            selectedStatus: null,
            minDate: null,
            maxDate: null,
            minAmount: null,
            maxAmount: null
        },
        ordersToShow: null,
        showVLazy: false,
        storeId: JSON.parse(localStorage.getItem('IEC_BO_store')).id
    }),
    created() {
        this.updatePageInfo(this.$route.meta)
        this.getIECOrders()

        this.$root.$on('changeStore', () => {
            this.storeId = JSON.parse(localStorage.getItem('IEC_BO_store')).id
            this.getOrdersToShow()
        });
    },
    computed:{
        formatMinDate() {
            if(this.isNotNull(this.filter.minDate)){
                return this.getDateFrFromDateEn(this.filter.minDate)
            }
            return null
        },
        formatMaxDate() {
            if(this.isNotNull(this.filter.maxDate)){
                return this.getDateFrFromDateEn(this.filter.maxDate)
            }
            return null
        },
        getOriginsAvailable(){
            let visibleDemos = this.getDemosVisibility(this.storeId)
            let origins = []
            origins = this.$t('origins')
            return origins.filter(origin => visibleDemos.includes(origin.value))
        }
    },
    methods:{
        getIECOrders(){
            this.$root.$emit('showLoader');
            this.showVLazy = false
            Basket.getIECOrders((data) => {
                for(let order of data){
                    order.isActive = false
                    this.getRandomEvolution(order)
                }

                this.orders = data
                this.sortOrders()
                this.showVLazy = true
                this.$root.$emit('hideLoader');
                this.getOrdersToShow()
            })
        },
        getIECOrdersByEmail(){
            this.$root.$emit('showLoader');
            this.showVLazy = false
            return new Promise((resolve) => {
                Basket.getIECOrdersByEmail(this.filter.email, (data) => {
                    for(let order of data){
                        order.isActive = false
                        this.getRandomEvolution(order)
                    }

                    this.ordersByEmail = data
                    this.sortOrders()
                    this.showVLazy = true
                    this.$root.$emit('hideLoader');
                    resolve()
                })
            })
        },
        getRandomEvolution(order){
            let evolution = this.$t('orders.evolutions');

            let randomNumber = Math.floor(Math.random()*evolution.length);
            order.state = evolution[randomNumber];
        },
        async getOrdersToShow(){
            let orders = null

            if(this.isNotNull(this.filter.email)){
                await this.getIECOrdersByEmail()
                orders = this.ordersByEmail
            }else{
                orders = this.orders
            }

            let visibleDemos = this.getDemosVisibility(this.storeId)
            orders = orders.filter(order => visibleDemos.includes(order.orderOrigin))

            if (this.isNotNull(this.filter.selectedOrigins)) {
                orders = orders.filter(order => this.filter.selectedOrigins.includes(order.orderOrigin))
            }

            if (this.isNotNull(this.filter.reference)) {
                orders = orders.filter(order => order.id.toString().includes(this.filter.reference))
            }

            if (this.isNotNull(this.filter.selectedStatus)) {
                orders = orders.filter(order => this.filter.selectedStatus.includes(order.state))
            }

            if (this.isNotNull(this.filter.minDate)) {
                orders = orders.filter(order => this.getMomentFromDateAndHour(order.updateDate).isSameOrAfter(this.getMomentFromDate(this.getDateFrFromDateEn(this.filter.minDate))))
            }

            if (this.isNotNull(this.filter.maxDate)) {
                orders = orders.filter(order => this.getMomentFromDateAndHour(order.updateDate).isSameOrBefore(this.getMomentFromDate(this.getDateFrFromDateEn(this.filter.maxDate))))
            }

            if (this.isNotNull(this.filter.minAmount)) {
                orders = orders.filter(order => order.totalAmount >= this.filter.minAmount)
            }

            if (this.isNotNull(this.filter.maxAmount)) {
                orders = orders.filter(order => order.totalAmount <= this.filter.maxAmount)
            }

            this.ordersToShow = orders
        },
        sortOrders(){
            if(this.sortDateDesc){
                if(this.isNotNull(this.orders))
                    this.orders.sort((a, b) => this.sortDesc(a, b))

                if(this.isNotNull(this.ordersByEmail))
                    this.ordersByEmail.sort((a, b) => this.sortDesc(a, b))
            }else{
                if(this.isNotNull(this.orders))
                    this.orders.sort((a, b) => this.sortAsc(a, b))

                if(this.isNotNull(this.ordersByEmail))
                    this.ordersByEmail.sort((a, b) => this.sortAsc(a, b))
            }
        },
        sortDesc(a, b){
            let momentA = this.getMomentFromDateAndHour(a.updateDate)
            let momentB = this.getMomentFromDateAndHour(b.updateDate)
            if(momentA.isAfter(momentB)){
                return -1
            }else if(momentB.isAfter(momentA)){
                return 1
            }
            return 0
        },
        sortAsc(a, b){
            let momentA = this.getMomentFromDateAndHour(a.updateDate)
            let momentB = this.getMomentFromDateAndHour(b.updateDate)
            if(momentA.isAfter(momentB)){
                return 1
            }else if(momentB.isAfter(momentA)){
                return -1
            }
            return 0
        },
        clearFilters(){
            this.filter = {
                selectedOrigins: null,
                reference: null,
                email: null,
                selectedStatus: null,
                minDate: null,
                maxDate: null,
                minAmount: null,
                maxAmount: null
            }
            this.ordersToShow = this.orders
            this.ordersByEmail = null
        }
    },
    watch:{
        sortDateDesc(){
            this.sortOrders()
        }
    }
};
</script>
<style scoped>
.v-text-field--outlined >>> fieldset {
    border-color: var(--primary-color);
}
.label{
    display: block;
    font-weight: bold;
    margin-bottom: 12px !important;
}
.custom-card{
    box-shadow: 0px 5px 20px 0px #d8d8d8 !important;
}
.table-wrapper{
    margin: 32px 0;
}
.table-wrapper .table-header{
    display: grid;
    grid-template-columns: repeat(5, 1fr) repeat(2, 2fr);
    justify-content: center;
    align-items: center;
}
.table-wrapper .table-header span{
    text-align: center;
    font-weight: bold;
}
.table-wrapper .horizontal-divider{
    margin: 16px 0;
    width: 100%;
    border: solid 1px;
    border-radius: 40px;
    color: var(--primary-color);
}
.sort{
    display: flex;
    place-self: center;
}
.icon-sort{
    vertical-align: middle;
}
</style>

<style>
.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections{
    min-height: initial !important;
}
</style>
