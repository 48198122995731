<template>
    <div :class="getOrderClass(order)">
    <span>
        <img :src="getDemoPicto()" class="demonstration-picto"/>
    </span>

        <span>{{ order.id }}</span>

        <span>
            {{ getBasketProductsCount() }}
            <OrderDetailedTooltip :order="order"></OrderDetailedTooltip>
        </span>

        <span>{{ formatPrice(order.totalAmount) }}€</span>

        <span v-if="order.orderOrigin == 'IEC_PAY_AND_DRIVE'">{{ $t('state.FINISH') }}</span>
        <span v-else>{{ $t('state.' + order.subState) }}</span>

        <span>{{ email }}</span>

        <span>
            {{ order.updateDate }}
            <v-tooltip v-if="isNotNull(order.suspect)" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" class="suspect-blink ms-2">mdi-alert-outline</v-icon>
                </template>
                <div v-html="getSuspectMessage()"></div>
            </v-tooltip>
        </span>

    </div>
</template>

<script>

var Customer = require("@/assets/js/Customer");
import OrderDetailedTooltip from "@/components/IEC/OrderDetailedTooltip";

export default {
    name: 'OrderItem',
    components: {OrderDetailedTooltip},
    props: ['order'],
    data() {
        return {
            email: "",
            state: ""
        }
    },
    created() {
        Customer.getLoyaltyInfos(this.order.customer.complexId).then(response => {
            if (response.status === 200 && this.isNotNull(response.data) && this.isNotNull(response.data.email)) {
                this.email = response.data.email
            } else {
                this.email = this.order.customer.email
            }
            this.order.email = this.email
        })

        if (this.isNull(this.order.subState)) {
            this.order.subState = "NEW"
        }
    },
    methods: {
        getOrderClass(order) {
            if (this.isNotNull(order.suspect)) {
                return "table-elmt-wrapper suspect-order my-1 py-4"
            } else {
                return "table-elmt-wrapper my-1 py-4"
            }
        },
        getDemoPicto() {
            switch (this.order.orderOrigin) {
                case "IEC_SCAN_AND_PAY":
                    return require('@/assets/img/picto_scan_and_pay.png')
                case "IEC_EAT_AND_PAY":
                    return require('@/assets/img/picto_pay_at_table.png')
                case "IEC_CLICK_AND_COLLECT":
                    return require('@/assets/img/picto_click_and_collect.png')
                case "IEC_PAY_AND_DRIVE":
                    return require('@/assets/img/picto_pay_and_drive.png')
                default:
                    return ""
            }
        },
        getBasketProductsCount() {
            let count = 0
            this.order.basketProducts.forEach(product => count += product.qty)
            return count
        },
        getSuspectMessage() {
            let message = ""

            let jsonSuspect = JSON.parse(this.order.suspect)

            Object.keys(jsonSuspect).forEach(key => {
                let values = jsonSuspect[key]
                if (key === "PURCHASE_DURATION") {
                    message += this.$t("suspectReason." + key, {val: parseInt(values.value), configuredVal: values.configuredValue}) + "<br>"
                } else {
                    message += this.$t("suspectReason." + key, {val: values.value, configuredVal: values.configuredValue}) + "<br>"
                }
            })

            return message
        }
    }
}

</script>
<style scoped>
.table-elmt-wrapper {
    display: grid;
    grid-template-columns: repeat(5, 1fr) repeat(2, 2fr);
    text-align: center;
}

.suspect-order {
    background-color: #ff00002e;
    border-radius: 15px;
}

.demonstration-picto {
    width: 35px;
}
</style>
